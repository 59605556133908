@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

.floating-input > input:not(:focus)::placeholder,
.floating-input > select:not(:focus)::placeholder,
.floating-input > textarea:not(:focus)::placeholder {
  color: transparent;
}

.floating-input > input:focus,
.floating-input > textarea:focus,
.floating-input > input:not(:placeholder-shown),
.floating-input > textarea:not(:placeholder-shown) {
  @apply pt-8;
}

.floating-input > select:focus,
.floating-input > select:not(:placeholder-shown) {
  @apply pt-5 pb-0;
}

.floating-input > input:focus ~ label,
.floating-input > select:focus ~ label,
.floating-input > textarea:focus ~ label,
.floating-input > input:not(:placeholder-shown) ~ label,
.floating-input > select:not(:placeholder-shown) ~ label,
.floating-input > textarea:not(:placeholder-shown) ~ label {
  @apply opacity-75 scale-75 -translate-y-3 translate-x-1;
}

@media print {
  body * {
    visibility: hidden;
  }
  .printable,
  .printable * {
    visibility: visible;
  }
  .printable {
    position: absolute;
    left: 0;
    top: 0;
  }
}
